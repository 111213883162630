const React = require('react');
const { shape, string } = require('prop-types');
const { Button } = require('@andes/button');

const namespace = 'ui-seller-data-footer';

const Footer = ({ size, hierarchy, action }) => (
  <div className={`${namespace}__container`}>
    <Button size={size} hierarchy={hierarchy} href={action.target} srLabel={action.accessibility_text} fullWidth>
      {action.label.text}
    </Button>
  </div>
);

Footer.propTypes = {
  size: string,
  hierarchy: string,
  action: shape({
    label: shape({
      text: string,
    }),
    target: string,
  }).isRequired,
};

module.exports = Footer;
